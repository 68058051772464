body {
    background-color: white;
    color: #333;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

html,
body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

img {
    width: 100%;
    height: auto;
}

.img-small {
    width: 50%;
    height: auto;
}

.img-med {
    width: 75%;
    height: auto;
}

.headshot {
    width: 300px;
    height: auto;
}

.container {
    width: 100%;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.row {
    display: flex;
    justify-content: space-between;
}

.col {
    flex: 1;
    padding: 15px;
    box-sizing: border-box;
}

.col-wider {
    flex: 2;
    border: 2px solid blue;
}

.col-even-wider {
    flex: 3;
    border: 2px solid green;
}

.col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.img-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-around;
    background-color: #0D3B66;
    padding: 10px;
    z-index: 1000;
}

.nav-item-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.nav-item {
    color: white;
    text-decoration: none;
    font-size: 20px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.nav-item-container:hover {
    cursor: pointer;
    background-color: #1C7293;
}

.intro {
    font-size: larger;
}

.intro-link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}

.intro-link:hover {
    color: #0056b3;
}

.contact-link {
    color: #1C7293;
    text-decoration: none;
    font-size: 18px;
    transition: all 0.3s ease-in-out;
}

.contact-link:hover {
    color: #76a5af;
    font-size: 20px;
}

.img-link {
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}

.img-link img {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.project-link {
    display: inline-block;
    background-color: #1C7293;
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    margin-top: 10px;
}

.project-link:hover {
    background-color: #76a5af;
}

.footer {
    margin-top: 50px;
    height: 100px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    font-weight: 300;
}

.blog-title {
    color: black;
    text-align: center;
    padding: 20px;
}

.blogpost {
    background-color: white;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blogpost-title {
    color: darkblue;
    padding: 10px 0;
}

.blogpost-content {
    color: slategray;
    line-height: 1.6;
}

.blogpost-date {
    color: #888888;
    font-size: 0.8em;
    margin-top: 10px;
}

.blogpost-tag {
    display: inline-block;
    background-color: #1e90ff;
    color: white;
    padding: 5px 10px;
    margin: 5px 5px 5px 0;
    border-radius: 3px;
    font-size: 0.8em;
}

.blogpost-tags {
    margin-top: 10px;
}

.tags-label {
    color: black;
    margin-bottom: 5px;
}

.blog-filters {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.blog-filters button {
    background-color: #1e90ff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.blog-filters button:hover {
    background-color: #187bcd;
}

.blog-filters button:active {
    background-color: #105e91;
}

.projects-container {
    background-color: #ffffff;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 20px;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    gap: 20px;
}

.project-box {
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.media {
    max-width: 100%;
    max-height: 350px;
    width: auto;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.project-content {
    padding: 15px;
    box-sizing: border-box;
}

.intro-box {
    background-color: #f4f4f4;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 15px auto 0px auto;
    max-width: 70%;
    overflow: hidden;
}

.contact-box {
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px auto;
    max-width: 75%;
    overflow: hidden;
}

.social-media-button {
    width: 50px;
    height: auto;
    margin: .4rem;
    padding: 10px;
}

.section-offset {
    margin-top: 60px;
    padding-top: 0px;
}

.projects-container h1 {
    text-align: center;
    font-size: 2.5em;
}

@media (max-width: 768px) {

    .contact-box,
    .intro-box {
        max-width: 100%;
        padding: 10px;
        margin: 5px auto;
    }

    .col {
        flex: 1;
        padding: 5px;
        box-sizing: border-box;
    }

    .projects-grid {
        grid-template-columns: 1fr;
    }

    .media,
    .img-med {
        max-width: 50%;
        height: auto;
    }

    .nav-item {
        font-size: 16px;
    }

    h1,
    h2 {
        font-size: 1.5em;
    }

    p {
        font-size: 1em;
    }

    .intro-row {
        flex-direction: column-reverse;
    }

    .intro-row .col {
        flex: none;
        width: 100%;
    }

    .intro-row .img-center img {
        width: 100%;
        height: auto;
    }

    .contact-row {
        flex-direction: column;
    }

    .contact-row .col {
        flex: none;
        width: 100%;
    }
}